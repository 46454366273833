
import React, { Component } from 'react'
import Header from './Partials/Header'
import FooterComp from './Partials/Footer'
import FormStep1 from './Steps/Step1'
import StepComp from './Steps'
import FormStep2 from './Steps/Step2'
import FormStep3 from './Steps/Step3'
import Step99 from './Steps/Step99'
import FormStep4 from './Steps/Step4'
import FormStep5 from './Steps/Step5'
import FormStep6 from './Steps/Step6'
import FormStep7 from './Steps/Step7'
import FormStep8 from './Steps/Step8'
import FormStep9 from './Steps/Step9'
import FormStep10 from './Steps/Step10'
import { Form } from 'antd'



class App extends Component {
  state = {
    current: 1,
    history: [],
    data: {}
  }
  forms = {
    FormStep1: FormStep1,
    FormStep2: FormStep2,
    FormStep3: FormStep3,
    FormStep4: FormStep4,
    FormStep5: FormStep5,
    FormStep6: FormStep6,
    FormStep7: FormStep7,
    FormStep8: FormStep8,
    FormStep9: FormStep9,
    FormStep10: FormStep10,
    FormStep99: Step99
  }
  onSubmit(form, current) {
    let history = [...this.state.history]
    history.push(this.state.current)
    let data = { ...this.state.data }
    data["step_" + this.state.current] = form
    this.setState({
      history,
      current,
      data
    })
  }
  onBack() {
    let history = [...this.state.history]
    this.setState({
      current: history.pop(),
      history
    })
  }
  reset() {
    this.setState({
      current: 1,
      history: [],
      data: {}
    })
  }
  renderForm() {
    let FORM = this.forms["FormStep" + this.state.current]
    return <FORM reset={() => this.reset()} data={this.state.data} onBack={() => this.onBack()} onSubmit={(form, current) => this.onSubmit(form, current)} />
  }
  render() {

    return (
      <div style={{ maxWidth: 1170, margin: "0 auto" }}>
        <Header />
        <StepComp step={2} max={10} />
        {this.renderForm()}
        <FooterComp />
      </div>
    )
  }
}

export default App
