
import React, { Component } from 'react'

import { Card, Layout, Menu, Form, Button, Col, Row } from 'antd'
import logo from '../img/logo.png'

const { Meta } = Card;
const { Header, Footer, Sider, Content } = Layout;

class FooterComp extends Component {
    render() {

        return (
            <div style={{ boxShadow: "0 4px 4px -1px rgba(0,0,0,.15)",border:0, marginTop:20,marginBottom:20}}>
                <Card style={{ border:0}}>

                    <Row>
                        <Col span={6} xs={24} sm={12} md={8} lg={8} xl={6} style={{marginBottom:20}}>

                            <Meta style={{ marginBottom: 20 }} title={<img height="27" src={logo} />} />
                            markilux GmbH + Co. KG<br/>Hansestraße 53<br/>48282 Emsdetten<br/>Telefon: +49 (0) 25 72 / 15 31-330<br/>Fax: +49 (0) 25 72 / 15 31 682<br/>info@markilux.com<br/>www.markilux.com<br/>
                        </Col>
                        <Col span={6} >
                            <Meta style={{ marginBottom: 15, marginTop:5 }} title="Info" />
                            <a target="_blank" href="https://www.markilux.com/de-de/datenschutz" style={{color:"#7b7b7b"}}>Datenschutz</a><br/>
                            <a target="_blank" href="https://www.markilux.com/de-de/ekb" style={{color:"#7b7b7b"}}>EKB</a><br/>
                            <a target="_blank" href="https://www.markilux.com/de-de/agb" style={{color:"#7b7b7b"}}>AGB</a><br/>
                            <a target="_blank" href="https://www.markilux.com/de-de/impressum" style={{color:"#7b7b7b"}}>Impressum</a><br/>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }
}

export default FooterComp
