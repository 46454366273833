
import React, { Component } from 'react'

import { Card, Layout, InputNumber, Form, Radio, Input, Button } from 'antd'

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step3 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let nextPage = 8
                if (values.productType == "Terassen- und Balkonmarkisen") {
                    nextPage=4
                } else if (values.productType == "Fenster - und Glasdachmarkisen") {
                    nextPage=5
                } else if (values.productType == "Markisensysteme für Freiräume (z.B. pergola, syncra, markant etc.)") {
                    nextPage=6
                } else if (values.productType == "Einzelkomponenten (z.B. Wärmestrahler)") {
                    nextPage=7
                }
                this.props.onSubmit(values, nextPage)

            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let inital = {...this.props.data.step_3}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Produkt- / Qualitätsmangel" extra="Abschnitt 3 von 10" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>

                                <Form.Item label="Welche Information liegt Ihnen vor?">
                                    {getFieldDecorator('documentType', {
                                        initialValue:inital.documentType,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio.Button value="markilux Betriebsauftragsnummer (BA)">markilux Betriebsauftragsnummer (BA)</Radio.Button>
                                            <Radio.Button value="markilux Auftragsnummer (AB)">markilux Auftragsnummer (AB)</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Nummer des vorher ausgewählten Geschäftsdokumentes. " >
                                    {getFieldDecorator('documentNr', {
                                        initialValue:inital.documentNr,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }, { type: "string", pattern: /^[0-9]+$/, message: 'Ihre Kundennummer besteht nur aus Zahlen' }],
                                    })(<Input  />)}
                                </Form.Item>
                                <Form.Item label="Wählen Sie die zutreffende Produkt Gruppierung aus.">
                                    {getFieldDecorator('productType', {
                                        initialValue:inital.productType,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio style={{display:"block"}} value="Terassen- und Balkonmarkisen">Terassen- und Balkonmarkisen</Radio>
                                            <Radio style={{display:"block"}} value="Fenster - und Glasdachmarkisen">Fenster - und Glasdachmarkisen</Radio>
                                            <Radio style={{display:"block"}} value="Markisensysteme für Freiräume (z.B. pergola, syncra, markant etc.)">Markisensysteme für Freiräume (z.B. pergola, syncra, markant etc.)</Radio>
                                            <Radio style={{display:"block"}} value="Markisentuch / -volant (Einzellieferung)">Markisentuch / -volant (Einzellieferung)</Radio>
                                            <Radio style={{display:"block"}} value="Einzelkomponenten (z.B. Wärmestrahler)">Einzelkomponenten (z.B. Wärmestrahler)</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={this.props.onBack} style={{marginRight:10}}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FormStep3 = Form.create({ name: 'horizontal_login' })(Step3);
export default FormStep3
