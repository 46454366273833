
import React, { Component } from 'react'

import { Card, Layout, Checkbox, Form, Radio, Result, Button } from 'antd'

const { Header, Footer, Sider, Content } = Layout;

class Step99 extends Component {

    state = {
        send: false,
        sending: false,
        dsvgo: false,
        agbs: false
    }
    sendData() {
        this.setState({
            send: true
        })
    }
    render() {
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Abschließen" extra="Alle Daten ausgefüllt" bordered={false}>
                            {!this.state.send && <div>
                                <h3>Forumlar senden</h3>
                                <p>Sie haben erfolgreich alle notwendigen felder ausgefüllt. Nun müssen Sie nurnoch die Daten absenden durch das bestätigen unserer Datenschutzbestimmungen und allgemeinen Geschäftsbedingungen. </p>
                                <Checkbox onChange={() => this.setState({ dsvgo: !this.state.dsvgo })} checked={this.state.dsvgo} >Ich habe die <a target="_blank" href="https://www.markilux.com/de-de/datenschutz">Datenschutzbestimmungen</a> zur Kenntnis genommen und akzeptiere sie.</Checkbox><br />
                                <Checkbox onChange={() => this.setState({ agbs: !this.state.agbs })} checked={this.state.agbs}>Ich habe die <a target="_blank" href="https://www.markilux.com/de-de/agb">Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen und akzeptiere sie.</Checkbox><br /><br />

                                <Button onClick={this.props.onBack} style={{ marginRight: 10 }} type="default" size="large">Zurück</Button>
                                <Button onClick={this.sendData.bind(this)} loading={this.state.sending} disabled={!(this.state.dsvgo && this.state.agbs)} type="primary" size="large">Absenden</Button>
                            </div>}
                            {this.state.send && <Result
                                status="success"
                                title="Erfolgreich Reklamationsmeldung gesendet"
                                extra={[
                                    <Button onClick={() => { window.open("https://www.markilux.com", "_blank") }} type="primary" key="website">Zur Markilux Webseite</Button>,
                                    <Button onClick={this.props.reset} key="restart">Neustarten</Button>,
                                ]}
                            />}
                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

export default Step99
