
import React, { Component } from 'react'

import { Card, Layout, InputNumber, Form, Radio, Input, Button } from 'antd'

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step1 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let nextPage = 2
                if (values.rmaType == "Produkt- /  Qualitätsmangel") {
                    nextPage=3
                }
                this.props.onSubmit(values, nextPage)
                
            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        let inital = {...this.props.data.step_1}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Reklamationsmeldung" extra="Abschnitt 1 von 10" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Form.Item label="E-Mail-Adresse des Fachpartners">
                                    {getFieldDecorator('famail', {
                                        initialValue:inital.famail,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }, { type: "email", message: 'Bitte geben Sie eine korrekte E-Mail-Adresse an.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Vor- u. Nachname (Anprechpartner/in beim Fachpartner)">
                                    {getFieldDecorator('faname', {
                                        initialValue:inital.faname,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Kundennummer "  extra="Ihre Kundennummer können Sie unseren Geschäftsdokumenten entnehmen.">
                                    {getFieldDecorator('customernr', {
                                        initialValue:inital.customernr,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }, { type: "string", pattern: /^[0-9]+$/, message: 'Ihre Kundennummer besteht nur aus Zahlen' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Welche Art der Reklamation liegt vor?">
                                    {getFieldDecorator('rmaType', {
                                        initialValue:inital.rmaType,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio.Button value="Unvollständige Lieferung / Falschlieferung">Unvollständige Lieferung / Falschlieferung</Radio.Button>
                                            <Radio.Button value="Produkt- /  Qualitätsmangel">Produkt- /  Qualitätsmangel</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FromStep1 = Form.create({ name: 'horizontal_login' })(Step1);
export default FromStep1
