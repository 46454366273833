
import React, { Component } from 'react'

import { Card, Layout, Checkbox, Form, Radio, Input, Button, Select } from 'antd'

const { Option } = Select;

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step8 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let nextPage = 9
                if (values.customerdata == "Nein") {
                    nextPage = 10
                }
                this.props.onSubmit(values, nextPage)
            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let inital = {...this.props.data.step_8}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Reklamationsgrund" extra="Abschnitt 8 von 10" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Form.Item label="Gestell">
                                    {getFieldDecorator('gestell', {
                                        initialValue:inital.gestell,
                                    })(
                                        <Checkbox.Group>
                                            <Checkbox style={{display:"block", paddingLeft:8}} value="Geräuschbildung Gestell">Geräuschbildung Gestell</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Geräuschbildung Gelenkarme">Geräuschbildung Gelenkarme</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Beschichtungsfehler (Lackschaden, Kratzer etc)">Beschichtungsfehler (Lackschaden, Kratzer etc)</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Teilebruch">Teilebruch</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Verformung / Beule / Preßfehler">Verformung / Beule / Preßfehler</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Markise schließt nicht">Markise schließt nicht</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Markise öffnet nicht (Anlage klemmt)">Markise öffnet nicht (Anlage klemmt)</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Gurtriß / Gurtproblem">Gurtriß / Gurtproblem</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Problem tracfix - Schienen (Laufverhalten)">Problem tracfix - Schienen (Laufverhalten)</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Anfertigungsfehler Gestell (z.B. falsche Größe, Farbe, Motorseite etc.)">Anfertigungsfehler Gestell (z.B. falsche Größe, Farbe, Motorseite etc.)</Checkbox>
                                        </Checkbox.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Tuch">
                                    {getFieldDecorator('tuch', {
                                        initialValue:inital.tuch,
                                    })(
                                        <Checkbox.Group>
                                            <Checkbox style={{display:"block", paddingLeft:8}} value="hängende Außensäume">hängende Außensäume</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Welligkeiten / Faltenbildung">Welligkeiten / Faltenbildung</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Graubruch / Knicke / Fleck">Graubruch / Knicke / Fleck</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Webfehler">Webfehler</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Problem tracfix - Tuch (Reißverschluß)">Problem tracfix - Tuch (Reißverschluß)</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Anfertigungsfehler Tuch (z.B. falsche Größe, Dessin, Naht etc.)">Anfertigungsfehler Tuch (z.B. falsche Größe, Dessin, Naht etc.)</Checkbox>
                                        </Checkbox.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Elektronik">
                                    {getFieldDecorator('Elektronik', {
                                        initialValue:inital.Elektronik,
                                    })(
                                        <Checkbox.Group>
                                            <Checkbox style={{display:"block", paddingLeft:8}} value="Funktionsstörung - Antrieb (Motor)">Funktionsstörung - Antrieb (Motor)</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Funktionsstörung - elektronisches Zubehör (vibrabox, Wärmestrahler)">Funktionsstörung - elektronisches Zubehör (vibrabox, Wärmestrahler)</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Funktionsstörung - Beleuchtung">Funktionsstörung - Beleuchtung</Checkbox>
                                            <Checkbox style={{display:"block"}} value="Funkprogrammierung">Funkprogrammierung</Checkbox>
                                        </Checkbox.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Beschreibung Ihrer Reklamation (optional)"  extra="maximal 300 Zeichen">
                                    {getFieldDecorator('description', {
                                        initialValue:inital.description,
                                        rules: [{max:300,message:"Die Nachricht darf nicht 300 Zeichen überschreiten"} ],
                                    })(<Input.TextArea rows={4} />)}
                                </Form.Item>
                                <Form.Item label="Endverbraucher Informationen (Kontaktdaten)">
                                    {getFieldDecorator('customerdata', {
                                        initialValue:inital.customerdata,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio.Button value="Ja">Ja (Kontaktinformationen mitteilen)</Radio.Button>
                                            <Radio.Button value="Nein">Nein (Kontaktinformationen nicht mitteilen)</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={this.props.onBack} style={{ marginRight: 10 }}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FormStep8 = Form.create({ name: 'horizontal_login' })(Step8);
export default FormStep8
