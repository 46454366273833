
import React, { Component } from 'react'
import { Steps } from 'antd';
const { Step } = Steps;

const stepStyle = {
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
  };
class StepComp extends Component {
    render() {

        const current = this.props.step;
        return (
            <div >

            </div>
        )
    }
}

export default StepComp
