
import React, { Component } from 'react'

import { Card, Layout, Menu, Form, Button, Col, Row } from 'antd'
import logo from '../img/logo.png'

const { Meta } = Card;
const { Header, Footer, Sider, Content } = Layout;

class HeaderComp extends Component {
    render() {

        return (
            <div style={{ boxShadow: "0 4px 4px -1px rgba(0,0,0,.15)", marginBottom: 20, borderBottomLeftRadius: 5, borderBottomRightRadius: 5,overflow:"hidden", position:"sticky", top:0, zIndex:100}}>
                <Card style={{ borderLeft: 0, borderRight: 0 }}>

                    <Row>
                        <Col span={12}>

                            <Meta title={<img height="27" src={logo} />} />RMA-Formular
                        </Col>
                        <Col span={12} style={{textAlign:"right"}}>
                            <Button onClick={()=>{window.open("https://www.markilux.com","_blank")}} type="dashed">Zurück zu Markliux</Button>
                        </Col>
                    </Row>
                </Card>
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={['1']}
                >
                    <Menu.Item key="1">Standart Forumlar</Menu.Item>
                </Menu>
            </div>
        )
    }
}

export default HeaderComp
