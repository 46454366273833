
import React, { Component } from 'react'

import { Card, Layout, InputNumber, Form, Radio, Input, Button } from 'antd'

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step2 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values, 99)

            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let inital = {...this.props.data.step_2}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Unvollständige Lieferung / Falschlieferung" extra="Abschnitt 2 von 2" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>

                                <Form.Item label="Welche Art der Reklamation liegt vor?">
                                    {getFieldDecorator('rmaSubType', {
                                        initialValue:inital.rmaSubType,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio.Button value="Unvollständige Lieferung">Unvollständige Lieferung</Radio.Button>
                                            <Radio.Button value="Falschlieferung">Falschlieferung</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Welche Information liegt Ihnen vor?">
                                    {getFieldDecorator('documentType', {
                                        initialValue:inital.documentType,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio.Button value="markilux Betriebsauftragsnummer (BA)">markilux Betriebsauftragsnummer (BA)</Radio.Button>
                                            <Radio.Button value="markilux Auftragsnummer (AB)">markilux Auftragsnummer (AB)</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Nummer des vorher ausgewählten Geschäftsdokumentes. " >
                                    {getFieldDecorator('documentNr', {
                                        initialValue:inital.documentNr,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }, { type: "number", message: 'Die Dokumentennummer besteht nur aus Zahlen.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Was wird reklamiert? "  extra="max. 200 Zeichen - Welcher Artikel, welche Menge, falsche Liefermenge, korrekte Liefermenge etc.">
                                    {getFieldDecorator('product', {
                                        initialValue:inital.product,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }, {max:200,message:"Die Nachricht darf nicht 200 Zeichen überschreiten."} ],
                                    })(<Input.TextArea rows={4} />)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={this.props.onBack} style={{marginRight:10}}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FormStep2 = Form.create({ name: 'horizontal_login' })(Step2);
export default FormStep2
