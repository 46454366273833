
import React, { Component } from 'react'

import { Card, Layout, InputNumber, Form, Radio, Input, Button, Select } from 'antd'

const { Option } = Select;

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step6 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values, 8)
            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let inital = {...this.props.data.step_6}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Markisensysteme für Freiräume" extra="Abschnitt 6 von 10" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>

                                <Form.Item label="Bitte wählen Sie das zu reklamierende Produkt aus." hasFeedback>
                                    {getFieldDecorator('product', {
                                        initialValue:inital.product,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Produkt auswählen">
                                            <Option value="markilux pergola 110 / 210">markilux pergola 110 / 210</Option>
                                            <Option value="markilux pergola compact">markilux pergola compact</Option>
                                            <Option value="markilux syncra">markilux syncra</Option>
                                            <Option value="markilux planet">markilux planet</Option>
                                            <Option value="markilux markant">markilux markant</Option>
                                            <Option value="markilux construct">markilux construct</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={this.props.onBack} style={{ marginRight: 10 }}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FormStep6 = Form.create({ name: 'horizontal_login' })(Step6);
export default FormStep6
