
import React, { Component } from 'react'

import { Card, Layout, InputNumber, Form, Radio, Input, Button, Select } from 'antd'

const { Option } = Select;

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step9 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values, 10)
            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let inital = {...this.props.data.step_9}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Informationen zum Endverbraucher" extra="Abschnitt 9 von 10" bordered={false}>
                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Form.Item label="Anrede">
                                    {getFieldDecorator('salutation', {
                                        initialValue:inital.salutation,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(
                                        <Radio.Group>
                                            <Radio.Button value="Frau">Frau</Radio.Button>
                                            <Radio.Button value="Herr">Herr</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item label="Vorname und Nachname">
                                    {getFieldDecorator('customername', {
                                        initialValue:inital.customername,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Straße und Hausnummer">
                                    {getFieldDecorator('customerstreet', {
                                        initialValue:inital.customerstreet,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="PLZ">
                                    {getFieldDecorator('customerpostcode', {
                                        initialValue:inital.customerpostcode,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }, { type: "string", pattern: /^[0-9]+$/, message: 'Ihre Kundennummer besteht nur aus Zahlen' } ],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Wohnort">
                                    {getFieldDecorator('customercity', {
                                        initialValue:inital.customercity,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Festnetz Nummer und/oder Handy Nummer">
                                    {getFieldDecorator('customerphone', {
                                        initialValue:inital.customerphone,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={this.props.onBack} style={{ marginRight: 10 }}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FormStep9 = Form.create({ name: 'horizontal_login' })(Step9);
export default FormStep9
