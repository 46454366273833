
import React, { Component } from 'react'

import { Card, Layout, Form, Button, Upload, Icon, message } from 'antd'

const { Dragger } = Upload;

const { Header, Footer, Sider, Content } = Layout;

class Step9 extends Component {

    state = {
        files:[]
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.onSubmit(this.state.files, 99)
    };
    render() {
        const props = {
            name: 'file',
            multiple: true,
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange(info) {
                const { status } = info.file;
                this.setState({
                    files: info.fileList
                })
                if (status !== 'uploading') {
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        let inital = {...this.props.data.step_10}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Bildmaterial" extra="Abschnitt 10 von 10" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Dragger  {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                    </p>
                                    <p className="ant-upload-text">Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen</p>
                                    <p className="ant-upload-hint">maximal 5 Anhänge mit einem maximal Volumen von 100MB</p>
                                </Dragger>
                                <Form.Item style={{marginTop:20}}>
                                    <Button type="default" onClick={this.props.onBack} style={{ marginRight: 10 }}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" >Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

export default Step9
