
import React, { Component } from 'react'

import { Card, Layout, InputNumber, Form, Radio, Input, Button, Select } from 'antd'

const { Option } = Select;

const { Header, Footer, Sider, Content } = Layout;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class Step7 extends Component {
    componentDidMount() {
        // To disabled submit button at the beginning.
        //this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values, 8)
            }
        });
    };
    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let inital = {...this.props.data.step_7}
        return (
            <div style={{ maxWidth: 1170, margin: "0 auto" }}>
                <Layout>
                    <Content>
                        <Card style={{ boxShadow: "0 3px 6px -1px rgba(0,0,0,.1)" }} title="Einzelkomponenten" extra="Abschnitt 7 von 10" bordered={false}>

                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Form.Item label="Bitte benennen Sie das zu reklamierende Produkt.">
                                    {getFieldDecorator('product', {
                                        initialValue:inital.product,
                                        rules: [{ required: true, message: 'Bitte bearbeiten Sie das Pflichtfeld.' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={this.props.onBack} style={{ marginRight: 10 }}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Weiter</Button>
                                </Form.Item>
                            </Form>

                        </Card>
                    </Content>
                </Layout>
            </div>
        )
    }
}

const FormStep7 = Form.create({ name: 'horizontal_login' })(Step7);
export default FormStep7
